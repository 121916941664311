import { FC } from "react";
import { Link } from "react-router-dom";
import { ReactComponent as NevaLogo } from "src/assets/icons/neva.svg";
import { routes } from "src/routes";

export const Footer: FC = () => {
  return (
    <footer className="w-full pt-[100px] text-white">
      <div className="container">
        <div className="grid grid-cols-12 md:gap-x-7 gap-y-5">
          <div className="lg:col-span-6 col-span-12">
            <p className="text-xl mb-[30px]">Ready to experience streamlined DeFi and GameFi with zero-knowledge technology? Take the next step and explore Neva today!</p>
            <a className="flex gap-[10px] bg-white text-black px-[23px] py-[17px] rounded-[33px] max-w-max" href="https://docs.neva.network/" rel="noreferrer" target="_blank">
              <span>Explorer now</span>
              <svg fill="none" height="20" viewBox="0 0 21 20" width="21" xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M20.7075 9.29251C20.8005 9.38538 20.8742 9.49567 20.9246 9.61707C20.9749 9.73846 21.0008 9.86859 21.0008 10C21.0008 10.1314 20.9749 10.2615 20.9246 10.3829C20.8742 10.5043 20.8005 10.6146 20.7075 10.7075L11.7075 19.7075C11.6146 19.8004 11.5043 19.8741 11.3829 19.9244C11.2615 19.9747 11.1314 20.0006 11 20.0006C10.8686 20.0006 10.7385 19.9747 10.6171 19.9244C10.4957 19.8741 10.3854 19.8004 10.2925 19.7075C10.1996 19.6146 10.1259 19.5043 10.0756 19.3829C10.0253 19.2615 9.99944 19.1314 9.99944 19C9.99944 18.8686 10.0253 18.7385 10.0756 18.6171C10.1259 18.4957 10.1996 18.3854 10.2925 18.2925L17.5863 11H1C0.734784 11 0.48043 10.8947 0.292893 10.7071C0.105357 10.5196 0 10.2652 0 10C0 9.73479 0.105357 9.48044 0.292893 9.2929C0.48043 9.10536 0.734784 9.00001 1 9.00001H17.5863L10.2925 1.70751C10.1049 1.51987 9.99944 1.26537 9.99944 1.00001C9.99944 0.734643 10.1049 0.480147 10.2925 0.292507C10.4801 0.104866 10.7346 -0.000549314 11 -0.000549316C11.2654 -0.000549318 11.5199 0.104866 11.7075 0.292507L20.7075 9.29251Z" fill="#019F9F" />
              </svg>
            </a>
          </div>
          <div className="lg:col-span-2 md:col-span-4 col-span-12 flex flex-col">
            <h5 className="text-xl mb-[30px]">Information</h5>
            <Link className="text-lg mb-5" to={routes.home.path}>Home</Link>
            <a className="text-lg mb-5" href="https://neva.network/about-us" rel="noreferrer" target="_blank">About us</a>
            <a className="text-lg mb-5" href="https://docs.neva.network/neva-token" rel="noreferrer" target="_blank">Neva token</a>
          </div>
          <div className="lg:col-span-2 md:col-span-4 col-span-12 flex flex-col">
            <h5 className="text-xl mb-[30px]">Docs</h5>
            <a className="text-lg mb-5" href="https://www.docs.neva.network/" rel="noreferrer" target="_blank">Get started</a>
            <a className="text-lg mb-5" href="https://docs.neva.network/neva-token/tokenomics" rel="noreferrer" target="_blank">Tokenomics</a>
            <a className="text-lg mb-5" href="https://docs.neva.network/roadmap" rel="noreferrer" target="_blank">Roadmap</a>
          </div>
          <div className="lg:col-span-2 md:col-span-4 col-span-12 flex flex-col">
            <h5 className="text-xl mb-[30px]">Legal</h5>
            <a className="text-lg mb-5" href="#">Contact us</a>
            <a className="text-lg mb-5" href="https://hello@neva.network/privacy-Policy" rel="noreferrer" target="_blank">Privacy Policy</a>
            <a className="text-lg mb-5" href="https://hello@neva.network/terms-of-Service" rel="noreferrer" target="_blank">Disclaimer</a>
          </div>
        </div>
        <NevaLogo className="max-w-full lg:mt-[190px] md:mt-[50px]" />
      </div>
      <div className="py-[50px] container xl:max-w-[1720px] flex justify-end">
        <p>© 2024 neva.</p>
      </div>
    </footer>
  );
};
