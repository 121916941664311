import { FC, useEffect, useState } from "react";
import { FaBars, FaXmark } from "react-icons/fa6";
import { Link } from "react-router-dom";
import { getPolicyCheck, setPolicyCheck } from "src/adapters/storage";
import { ReactComponent as ClockIcon } from "src/assets/icons/clock.svg";
import { useEnvContext } from "src/contexts/env.context";
import { useProvidersContext } from "src/contexts/providers.context";
import { PolicyCheck, WalletName } from "src/domain";
import { routes } from "src/routes";
import { getDeploymentName } from "src/utils/labels";
import { WalletList } from "src/views/login/components/wallet-list/wallet-list.view";
import { ConfirmationModal } from "src/views/shared/confirmation-modal/confirmation-modal.view";
import { NetworkSelector } from "src/views/shared/network-selector/network-selector.view";
import { Typography } from "src/views/shared/typography/typography.view";
// import { ReactComponent as SettingIcon } from "src/assets/icons/setting.svg";
// import { ReactComponent as PolygonZkEVMLogo } from "src/assets/polygon-zkevm-logo.svg";

export const Menu: FC = () => {
  const env = useEnvContext();
  const [show, setShow] = useState<boolean>(false)
  const [showButton, setShowButton] = useState<boolean>(false)
  const [selectedWallet, setSelectedWallet] = useState<WalletName>();
  const { connectedProvider, connectProvider } = useProvidersContext();
  const [showPolicyModal, setShowPolicyModal] = useState(false);
  const [deploymentName, setDeploymentName] = useState<string | undefined>('')

  const onConnectProvider = () => {
    setPolicyCheck();
    selectedWallet && connectProvider(selectedWallet);
    setShowPolicyModal(false);
  };

  const onCheckAndConnectProvider = (walletName: WalletName) => {
    setSelectedWallet(walletName);
    const checked = getPolicyCheck();
    if (checked === PolicyCheck.Checked) {
      void connectProvider(walletName);
    } else {
      setShowPolicyModal(true);
    }
  };

  useEffect(() => {
    if (env) {
      const ethereumChain = env.chains[0]
      setDeploymentName(getDeploymentName(ethereumChain))
    }
  }, [env])

  useEffect(() => {
    if (connectedProvider.status === "successful") {
      setShowButton(true)
    }
    else { 
      setShowButton(false)
    }
  }, [connectedProvider])

  return (
    <>
      <div className="container xl:max-w-[1720px] flex items-center mt-[50px] relative">
        {/* <div className="flex-1 xl:hidden lg:block hidden"></div> */}
        <nav className="2xl:container flex items-center z-50 gap-5">
          <div className="flex-1">
            <img alt="logo" className="sm:w-[126px] max-w-[126px] w-full" src="images/logo.png" />
          </div>
          <div className={`flex lg:bg-[#00000080] bg-black lg:rounded-[27px] text-white text-base font-medium lg:px-[25px] relative lg:gap-[10px] transition-all max-lg:fixed max-lg:top-0 max-lg:left-0 max-lg:h-full max-lg:flex-col max-lg:pt-5 max-lg:overflow-x-hidden ${show ? 'max-lg:w-[250px] px-[25px]' : 'max-lg:w-0 px-0'}`}>
            <Link className="p-[15px] max-xl:px-1 hover:text-[#019E9E]" to={routes.home.path}>Home</Link>
            <a className="p-[15px] max-xl:px-1 hover:text-[#019E9E]" href="https://neva.network/about-us" rel="noreferrer" target="_blank">About us</a>
            <a className="p-[15px] max-xl:px-1 hover:text-[#019E9E]" href="https://docs.neva.network/" rel="noreferrer" target="_blank">Get started</a>
            <a className="p-[15px] max-xl:px-1 hover:text-[#019E9E]" href="https://docs.neva.network/neva-token" rel="noreferrer" target="_blank">Neva token</a>
            <a className="p-[15px] max-xl:px-1 hover:text-[#019E9E]" href="https://docs.neva.network/roadmap" rel="noreferrer" target="_blank">Roadmap</a>
            <div className="md:hidden">
              <WalletList classes={showButton ? 'hidden' : ''} onSelectWallet={onCheckAndConnectProvider} />
            </div>
            <Link className={`md:hidden flex gap-[10px] bg-white rounded-[33px] p-[18px] text-black hover:bg-[#019F9F] hover:text-white group items-center mb-5 ${!showButton ? '!hidden' : ''}`} to={routes.activity.path}>
              <ClockIcon className="text-[#019F9F] group-hover:text-white" />
              <span className="">
                Activity
              </span>
            </Link>
            <button className="lg:hidden block absolute top-5 right-5 text-white" onClick={() => setShow(false)}>
              <FaXmark />
            </button>
          </div>
          <div className="flex-1 lg:block hidden"></div>
        </nav>
        <div className="flex-1 flex justify-end gap-[15px] min-w-min xl:absolute xl:top-0 xl:right-[15px]">
          <div className="max-md:hidden">
            <WalletList classes={showButton ? 'hidden' : ''} onSelectWallet={onCheckAndConnectProvider} />
          </div>
          <Link className={`md:flex hidden gap-[10px] bg-white rounded-[33px] p-[16px] text-black hover:bg-[#019F9F] hover:text-white group items-center z-[51] ${!showButton ? '!hidden' : ''}`} to={routes.activity.path}>
            <ClockIcon className="text-[#019F9F] group-hover:text-white" />
            <span className="">
              Activity
            </span>
          </Link>
          <div className={`flex ${!showButton ? 'hidden' : ''}`}>
            <NetworkSelector />
          </div>
          <button className="bg-white lg:hidden flex gap-[10px] w-[56px] h-[56px] px-4 py-[14px] justify-center rounded-full text-black font-semibold items-center hover:bg-[#019E9E] hover:text-white transition-all" onClick={() => setShow(true)}>
            <FaBars />
          </button>
        </div>
      </div>
      {showPolicyModal && (
        <ConfirmationModal
          message={
            <Typography type="body1">
              DISCLAIMER: This version of the Neva Apache will require frequent maintenance and
              may be restarted if upgrades are needed.
            </Typography>
          }
          onClose={() => setShowPolicyModal(false)}
          onConfirm={onConnectProvider}
          showCancelButton={false}
          title={`Welcome to the Neva apache ${deploymentName || ""}`}
        />
      )}
    </>
  );
};
